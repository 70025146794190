import React from 'react'
import sImg1 from '../../images/wedding-date/1.png'
import sImg2 from '../../images/wedding-date/2.png'
import sImg3 from '../../images/wedding-date/3.png'
import TimeCountDown from './countdown'
import { ReactComponent as Icon } from '../../images/icon/lya-letrasdorado.svg';


const WeddingDate = (props) => {

    return (
        <section className="wpo-hero-wedding-date">
            <div className="wpo-wedding-date-inner">
                <span>Save the Date</span>
                <h2>
                    <Icon />
                </h2>
                <p>¡El amor está en el aire y queremos compartirlo contigo! Únete a nuestra celebración el 14 de Diciembre de 2024</p>
                <span className="shape"><img src={sImg1} alt="" /> </span>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="clock-grids">
                            <div id="clock">
                                <TimeCountDown WeddingDate={props.WeddingDate}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shape-1">
                <img src={sImg2} alt="" />
            </div>
            <div className="shape-2">
                <img src={sImg3} alt="" />
            </div>
        </section>
    )
}

export default WeddingDate;